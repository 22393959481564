@import '_Constants.scss';
@import '_MCR_Constants.scss';

.welcome-footer {
  background-color: $DeepNavyBlue;
  display: flex;
  justify-content: center;
  padding: 48px 20px;

  .footer-container {
    width: 1140px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: MarkForMC;
    color: $White;

    .mc-logo {
      height: 40px;
      width: 65px;
      margin-top: 40px;
      margin-bottom: 24px;
    }
    .need-help {
      font-size: 24px;
      line-height: 36px;
      font-weight: 350;
      margin-bottom: 24px;
    }

    a.contact-group {
      color: $White;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 48px;
      img {
        width: 24px;
        height: 15px;
        margin-right: 5px;
      }
    }
    
    hr {
      border: 1px solid #555250;
      width: 744px;
      margin-left: 0;
      margin-bottom: 48px;
    }

    .copyright {
      max-width: 744px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 48px;
    }

    .provided-by-group {
      display: flex;
      align-items: center;
      font-weight: 350;
      font-size: 16px;
      line-height: 20px;
      img {
        width: 53px;
        height: 32px;
        margin-left: 16px;
      }
    }
  }

}