@import '../../../_Constants.scss';

button.v3-button {
  font-size: 14px;
  height: 44px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  color: $White;
  box-shadow: $SmallBoxShadow;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.15s ease-in;
  justify-content: center;
  svg {
    fill: $White;
  }

  svg:only-child {
    margin: 0 -10px;
  }

  &.loading {
    cursor: default;

    svg:only-child {
      margin: 0;
    }
    &:focus {
      outline: none;
    }
  }

  & :not(:first-child) {
    margin-left: 5px;
  }

  &.green {
    background-color: $JewelGreen;
    &.outline {
      border-color: $JewelGreen;
      color: $JewelGreen;
      svg {
        fill: $JewelGreen;
      }
      &:hover,
      &.loading {
        background-color: $JewelGreen;
        color: $White;
        svg {
          fill: $White;
        }
      }
      &:disabled {
        border-color: $JewelGreenA25;
        color: $JewelGreenA25;
        svg {
          fill: $JewelGreenA25;
        }
      }
    }
    &:hover,
    &.loading,
    &.loading {
      background-color: $BottleGreen;
    }
  }
  &.red {
    background-color: $BrightRed;
    &.outline {
      border-color: $BrightRed;
      color: $BrightRed;
      svg {
        fill: $BrightRed;
      }
      &:hover,
      &.loading {
        background-color: $BrightRed;
        color: $White;
        svg {
          fill: $White;
        }
      }
      &:disabled {
        border-color: $BrightRedA25;
        color: $BrightRedA25;
        svg {
          fill: $BrightRedA25;
        }
      }
    }
    &:hover,
    &.loading {
      background-color: $Scarlett;
    }
  }
  &.blue {
    background-color: $RoyalBlue;
    &.outline {
      border-color: $RoyalBlue;
      color: $RoyalBlue;
      svg {
        fill: $RoyalBlue;
      }
      &:hover {
        background-color: $RoyalBlue;
        color: $White;
        svg {
          fill: $White;
        }
      }
      &:disabled {
        border-color: $RoyalBlueA25;
        color: $RoyalBlueA25;
        svg {
          fill: $RoyalBlueA25;
        }
      }
    }
    &:hover {
      background-color: $ToryBlue;
    }
  }
  &.dark-blue {
    background-color: $NileBlue;
    &.outline {
      border-color: $NileBlue;
      color: $NileBlue;
      svg {
        fill: $NileBlue;
      }
      &:hover,
      &.loading {
        background-color: $NileBlue;
        color: $White;
        svg {
          fill: $White;
        }
      }
      &:disabled {
        border-color: $NileBlueA25;
        color: $NileBlueA25;
        svg {
          fill: $NileBlueA25;
        }
      }
    }
    &:hover,
    &.loading {
      background-color: $DeepNavyBlue;
    }
  }
  &.dark {
    background-color: $DeepNavyBlue;
    &.outline {
      border-color: $DeepNavyBlue;
      color: $DeepNavyBlue;
      svg {
        fill: $DeepNavyBlue;
      }
      &:hover,
      &.loading {
        background-color: $DeepNavyBlue;
        color: $White;
        svg {
          fill: $White;
        }
      }
      &:disabled {
        border-color: $DeepNavyBlueA25;
        color: $DeepNavyBlueA25;
        svg {
          fill: $DeepNavyBlueA25;
        }
      }
    }
    &:hover,
    &.loading {
      background-color: $FireFly;
    }
  }

  &.outline {
    border: 1px solid;
    background-color: $White;
  }

  &:disabled {
    background-color: $AthensGray;
    color: $Silver;
    svg {
      fill: $Silver;
    }
    cursor: default;
    &.outline {
      background-color: transparent;
    }
    &:hover,
    &.loading {
      background-color: $AthensGray;
      color: $Silver;
      svg {
        fill: $Silver;
      }
      &.outline {
        background-color: transparent;
      }
    }
  }
}
