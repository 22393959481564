@import '_Constants.scss';

.language-loading-container {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg.loading-spinner {
    fill: $PeriwinkleGray;
  }
}
