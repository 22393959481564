@import '../../../_Constants.scss';

.v3-side-drawer {
  position: fixed;
  z-index: 910;
  top: 0;
  right: -971px;
  width: 971px;
  height: 100%;
  display: flex;
  background-color: $AthensGray;
  // border: 1px solid $GeyserGray;
  // border-radius: 10px;
  box-shadow: $LargeBoxShadow;
  transition: right 0.3s ease-in-out;

  &.open {
    right: 0;
  }

  button.side-drawer-close-button {
    position: absolute;
    right: 25px;
    top: 25px;
    color: $FontColor;
    border-color: $FontColor;
    fill: $FontColor;
    z-index: 2; // because the dropshadow z-index: 1 fix on header, is same layer as this close button
    outline: none;
  }

  // ::-webkit-scrollbar {
  //   -webkit-appearance: none;
  //   width: 6px;
  //   height: 6px;
  // }
}

#v3-side-drawer-overlay {
  overflow: hidden;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $DeepNavyBlueA60;
}
