@import '../../../_Constants.scss';

.toast-container {
  // used to prevent the overflow from showing scrollbar while animating
  overflow: hidden;
  height: 300px;
  position: fixed;
  right: 0;
  top: 56px;
  z-index: 1000;

  .toast-alert-v1 {
    display: flex;
    position: absolute;
    right: 30px;
    top: 29px;
    width: 621px;
    min-height: 50px;
    box-sizing: border-box;
    z-index: 1;
    font-family: $rrFont;
    word-break: break-word;
    -webkit-animation: slide-in 0.85s forwards;
    -webkit-animation-delay: 0.5s;
    animation: slide-in 0.85s forwards;
    animation-delay: 0.5s;
    transform: translateX(calc(100% + 30px));

    .toast-alert-v1-left {
      display: flex;
      align-items: center;
      width: 166px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      .toast-alert-v1-icon {
        margin-left: 17px;
        width: 19px;
        display: flex;
        align-items: center;
      }
      .toast-alert-v1-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: -0.03em;
        margin-left: 12px;
        letter-spacing: -0.02em;
      }
    }

    .primary-v1 {
      background-color: $RoyalBlue;
      border: 1px solid $RoyalBlue;
      color: $White;
    }

    .success-v1 {
      background-color: $JewelGreen;
      border: 1px solid $JewelGreen;
      color: $White;
    }

    .warning-v1 {
      background-color: $CreamCanYellow;
      border: 1px solid $CreamCanYellow;
      color: $DeepNavyBlue;
    }

    .danger-v1 {
      background-color: $BrightRed;
      border: 1px solid $BrightRed;
      color: $White;
    }

    .toast-alert-v1-right {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      width: 455px;
      display: flex;
      align-items: center;
      .toast-alert-v1-message {
        width: 385px;
        color: $DeepNavyBlue;
        margin-left: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        a {
          text-decoration: underline;
          color: $RoyalBlue;
          font-weight: 600;
        }
      }

      .toast-alert-v1-close-button {
        margin-left: auto;
        margin-right: 16px;
        width: 20px;
      }
    }
  }

  .toast-alert-v2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 30px;
    top: 29px;
    width: 416px;
    min-height: 116px;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px;
    z-index: 1;
    font-family: $rrFont;
    word-break: break-word;
    -webkit-animation: slide-in 0.85s forwards;
    -webkit-animation-delay: 0.5s;
    animation: slide-in 0.85s forwards;
    animation-delay: 0.5s;
    transform: translateX(calc(100% + 30px));

    .toast-alert-v2-close-button {
      position: absolute;
      right: 16px;
      top: 15px;
      cursor: pointer;
      width: 20px;
    }

    .toast-alert-v2-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: -0.03em;
    }

    .toast-alert-v2-message {
      color: $DeepNavyBlue;
      margin-top: 10px;
      margin-bottom: 3px;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      a {
        text-decoration: underline;
        color: $RoyalBlue;
        font-weight: 600;
      }
    }
  }

  .primary {
    background-color: $RoyalBlueA05Solid;
    border: 1px solid $RoyalBlue;
    color: $RoyalBlue;
  }
  .success {
    background-color: $JewelGreenA05Solid;
    border: 1px solid $JewelGreen;
    color: $JewelGreen;
  }

  .warning {
    background-color: $CreamCanYellowA05Solid;
    border: 1px solid $CreamCanYellow;
    color: $NileBlue;
  }

  .danger {
    background-color: $BrightRedA05Solid;
    border: 1px solid $BrightRed;
    color: $BrightRed;
  }
}
@-webkit-keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
.toast-width-1 {
  width: 652px;
}
.toast-width-2 {
  width: 447px;
}
