@import '_Constants.scss';
@import '_MCR_Constants.scss';

.nav-items {
  display: flex;

  .sub-item {
    display: flex;
    margin: 0;
    align-items: center;
    padding: 1px 15px;
    background-color: $DeepNavyBlue;
    color: $PeriwinkleGray;
    width: 250px;
    min-height: 45px;
    outline: none;
    // &:focus, &:focus-visible {
    //   outline: 2px solid white;
    // }

    &.pointer {
      cursor: pointer;
    }

    &.no-hover:hover {
      background-color: $MCRBlack;
      color: $PeriwinkleGray;
    }

    // &:hover, &.active, &:focus { // for use when :focus-within is figured out
    &:hover,
    &:focus,
    &.active {
      background-color: $DianneBlue;
      color: $White;

      .sub-item-icon svg path {
        fill: $White;
      }
    }

    .sub-item-icon {
      padding-right: 12.5px;
      padding-top: 3px;
    }
  }

  .public-button-group {
    display: flex;
    align-items: center;
    button {
      padding: 0;
      height: 40px;
      margin-right: 24px;
      a {
        color: unset;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
      &.login {
        width: 95px;
      }
      &.signup {
        width: 107px;
      }
    }
  }

  .portal-name {
    display: flex;
    padding: 0 15px;
    background-color: $MCRBlack;

    span {
      flex: 1;
      display: flex;
      align-items: center;
      border-top: 2px solid $FiordBlue;
      border-radius: 2px;
      color: $White;
      font-weight: 600;
      font-size: 14px;
      padding-top: 12px;
    }
  }

  .switch-portals-link {
    padding: 0 15px;
    background-color: $MCRBlack;
    // font-size: 12px;
    color: $PeriwinkleGray;

    span {
      display: flex;
      min-height: 45px;
      align-items: center;
      border-bottom: 2px solid $FiordBlue;
    }

    &:hover {
      text-decoration: underline;
      color: $White;
    }
  }
}

nav#top-nav {
  background-color: $DeepNavyBlue;
  height: 56px;
  width: 100%;
  z-index: 15;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 1140px) {
    position: fixed;
    top: 0px;
  }

  .nav-body {
    width: 1140px;
    display: flex;

    .nav-left {
      display: flex;
      align-items: center;

      .navbar-logo {
        display: flex;
        align-items: center;

        color: $White;

        img.logo-svg {
          padding-right: 10px;
          height: 50px;
        }
      }
    }

    .nav-right {
      margin-left: auto;
      display: flex;
    }
  }
}
