$black: #000000;
$white: #ffffff;
$reconGreen: #6bc048;
$reconLightGreen: #7fab6b;
$reconDarkGreen: #79b153;
$reconRed: #e24445;
$reconBlue: #70a9d6;
$reconGray: #56565a;
$reconYellow: #fad000;
$reconOrange: #eb6822;
$lightGray: #c4c4c4;
$darkGray: #2b2b2b;
$mediumGray: #7d7d7d;
$lightBlue: #e7f4ff;
$link: $reconBlue;

$textColor: #4f4f51;
$secondaryTextColor: #969696;
$disabled: #e8e8e8;

$modalBorder: #e3e3e3;
$matrixBorder: #d9d8d8;

$font: 'Open Sans', sans-serif;
$font-mc: 'MarkForMC', sans-serif;

$paginationBorder: #ececec;
$paginationLink: #b0b0b0;
$paginationLinkDisabled: #6c757d;
$paginationBackground: #fff;
$paginationBackgroundActive: rgba(0, 0, 0, 0.05);

$alertCenterNavBackground: #f7f6f6;

$dashbaordTHColor: #b0b0b0;
$dashboardTHBorder: rgba(34, 36, 38, 0.1);
$dashbaordTHBorderBottomColor: #ececed;
$dashboardTHBackground: transparent;
$dashboardTableBackground: #fff;
$dashbaordTableColor: rgba(0, 0, 0, 0.87);
$dashboardTRColor: #4f4f51;
$dashboardVerticalSeparator: #ececec;
$dashboardWidgetSubtitle: #818184;

$dashboardColorBarStop: #e24445;
$dashboardColorBarFix: #f6c200;
$dashboardColorBarLimit: #70a9d6;
$dashboardColorBarProceed: #6bbf47;
$dashboardColorBarZero: #bdbdbd;

$dashboardRiskLabelBackground: #eaeaea;
$dashboardCurrentFilterLablelBorder: #eaeaea;
$dashboardSVGModalCloseColor: #70a9d6;
$dashbaordFiltersBucketTreeBorder: #ececec;
$dashboardRiskStructureHeaderBackground: #f2f2f2;
$dashboardFieldSVGColor: #b0b0b0;

$dashboardTotalVendorsCircleBackground: #f2f2f2;
$dashboardTotalVendorsCircleText: #555;
$dashbaordTotalVendorsBorderColor: #ebebeb;
$dashboardProgressBackground: #bdc3c7;
$dashboardProgressBorderColor: #ebebeb;

$dashboardPortfolioScoreBackground: #f2f2f2;
$dashboardPortfolioGreenCircleBackground: #69c041;
$dashboardPortfolioGreenTextShadow: #5da63b;

$dashboardPortfolioBlueCircleBackground: #6fa9d8;
$dashboardPortfolioBlueTextShadow: #659ac4;

$dashboardPortfolioYellowCircleBackground: #f6c200;
$dashboardPortfolioYellowTextShadow: #e6b500;

$dashboardPortfolioRedCircleBackground: #e72e2b;
$dashboardPortfolioRedTextShadow: #d53333;

$ITProfileHighValueAsset: #e53535;
$ITProfileMediumValueAsset: #70a9d6;
$ITProfileLowValueAsset: #6bbf47;
$ITProfileInfoRating: #b6b6b6;

$sidebarBorder: #d6d6d6;
$hoverDarken: #464647;

$tableHeaderColor: #b0b0b0;
$tableRowHover: #faf9f9;
$tableRowActive: #f2f2f2;
