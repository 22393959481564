@import '_Constants.scss';
@import '_MCR_Constants.scss';

.sign-up-container {
  margin-top: 60px;
  margin-bottom: 189px;
  display: flex;
  justify-content: center;

  .sign-up-grid {
    width: 1140px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;

    .mcr-benefits {
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
      }
      .benefits-subtitle {
        margin-top: 12px;
        font-size: 18px;
        font-weight: 400;
        line-height: 22.5px;
      }
      .benefit-item-container {
        display: flex;
        margin-top: 64px;

        .benefit-item-info {
          margin-left: 20px;

          h4 {
            font-size: 20px;
            line-height: 25px;
            font-weight: 600;
          }

          .benefit-item-description {
            margin-top: 8px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
          }
        }

      }
    }
  }
}






#gather-company-info-container {
  .v3-header {
    font-size: 28px;
    line-height: 35px;
    font-weight: 700;
  }

  .onboard-message {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 10px;
  }

  .user-onboard-form-container {
    margin-top: 32px;

    .account-already {
      margin-top: 32px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      a {
        color: $RoyalBlue;
        font-weight: 600;
      }
    }
  }

  div.v3-text-input {
    height: 100px;
  }
  

  .sign-up-button-group {
    .v3-button {
      flex: 1
    }
  }

  .agreement-container {
    margin-top: 10px;
    margin-bottom: 20px;
    input#agreement {
      height: 16px;
      width: 16px;
      margin-right: 8px;
      vertical-align: middle;
      cursor: pointer;
    }

    .agreement-message {
      line-height: 1.5;
      cursor: pointer;

      a {
        color: #173a56;
        font-weight: 600;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
