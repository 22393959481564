@import '_Constants.scss';

div.nav-item {
  cursor: default;
}

.nav-item {
  display: flex;
  position: relative;
  align-items: center;
  color: $PeriwinkleGray;
  fill: $PeriwinkleGray;
  padding: 0 10px;
  outline: none;
  svg {
    position: relative;
    top: 2px;
    &.spin {
      transition: 0.2s linear;
      transform: rotate(180deg);
    }
  }
  // &:hover,
  // &.active {
  &:hover {
  // &.active,
  // &:focus,
  // &:focus-within {
    background: $DianneBlue;
    color: $White;
    fill: $White;

    svg {
      transform: rotate(0deg);
    }

    .sub-menu {
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
      max-height: 90vh;
      overflow: auto;
    }
  }

  .sub-menu {
    top: 100%;
    left: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    display: flex;
    flex-direction: column;
    transition: all 0.15s ease;
    z-index: 999;
    // transition-delay: 0.15s;
  }
}
