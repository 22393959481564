@import '../../../_Constants.scss';

.v3-rating-bullet {
  display: flex;
  align-items: center;
  font-weight: 600;

  span {
    margin-left: 5px;
    color: $NileBlue;
  }

  &.f {
    fill: $BrightRed;
  }
  &.d {
    fill: $BurntOrange;
  }
  &.c {
    fill: $CreamCanYellow;
  }
  &.b {
    fill: $RoyalBlue;
  }
  &.a {
    fill: $JewelGreen;
  }
  &.na {
    fill: $GeyserGray;

    span {
      position: relative;
      right: -2px;
    }
  }
}
