@import '../../../_Constants.scss';

.v3-line-chart {
  position: relative;
  svg {
    .tick-label text {
      fill: $FiordBlue;
    }
  }
  .info-only-text {
    font-size: 16px;
    letter-spacing: -0.02em;
    // stroke: $NileBlue;
    font-weight: 600;
  }

  .chart-header.domain-detailed {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    height: 26px;
    // margin-bottom: 10px;

    .active-item-figure {
      display: flex;
      align-items: center;
      margin-left: 20px;

      .active-item-label {
        margin-right: 10px;
        font-size: 10px;
        color: $FiordBlue;
      }
      .active-date {
        font-size: 14px;
        font-weight: 600;
        color: $NileBlue;
      }
    }
  }
}
