@import '../../../_Constants.scss';

button.v3-icon-button {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;

  &.green {
    fill: $JewelGreen;
    &:disabled {
      fill: $JewelGreenA25;
    }
  }

  &.red {
    fill: $BrightRed;
    &:disabled {
      fill: $BrightRedA25;
    }
  }

  &.blue {
    fill: $RoyalBlue;
    &:disabled {
      fill: $RoyalBlueA25;
    }
  }

  &.dark-blue {
    fill: $NileBlue;
    &:disabled {
      fill: $NileBlueA25;
    }
  }

  &.dark {
    fill: $DeepNavyBlue;
    &:disabled {
      fill: $DeepNavyBlueA25;
    }
  }
}
