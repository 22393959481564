#portal-container > .global-loader {
  height: 85vh;
}

.v3-loading.with-overlay.global-loader {
  z-index: 1000;
  .overlay {
    overflow: hidden;
    position: fixed;
  }
}
