#mcr-tour-modal {
  display: flex;
  flex-direction: column;
  padding: 30px 25px;
  height: 250px;
  width: 450px;
  justify-content: space-between;

  .mcr-tour-modal-message {
    // margin-top: 30px;
  }

  .mcr-tour-modal-button-container {
    display: flex;
    justify-content: center;
    // margin-top: 50px;

    .v3-button {
      margin: 0 20px;
    }
  }
}
