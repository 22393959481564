@import '../../../_Constants.scss';

th.v3-th {
  font-size: 12px;
  line-height: 22px;
  font-weight: bold;
  padding-right: 10px;

  &.sortable {
    cursor: pointer;

    div.th-wrapper {
      // removed nowrap for i18n headers
      // white-space: nowrap;
      display: flex;
      align-items: center;
      position: relative;
    }

    div.th-text-svg-wrapper {
      // removed nowrap for i18n headers
      // white-space: nowrap;
      display: flex;
      align-items: center;
      padding: 2px 10px;
      margin-bottom: 5px;
      border-radius: 4px;
      position: relative;

      &.left {
        left: -10px;
      }
      &.right {
        right: -10px;
        margin-right: 10px;
      }

      &.sorted {
        background-color: $GeyserGrayA40;

        svg {
          color: $NileBlue;
        }

        &.asc svg {
          transform: rotate(0deg);
          transition-duration: 0.5s;
        }

        &.desc svg {
          transform: rotate(180deg);
          transition-duration: 0.5s;
        }
      }

      // &.asc svg {
      //   transform: rotate(0deg);
      //   transition-duration: .5s;
      // }
      //
      // &.desc svg {
      //   transform: rotate(180deg);
      //   transition-duration: .5s;
      // }
    }
    svg {
      color: $PeriwinkleGray;
      height: 12px;
      cursor: pointer;
      margin-left: 5px;
    }
  }

  &.sticky {
    position: sticky;
    top: 0;
    background-color: white;
  }

  &.left {
    text-align: left;

    .th-wrapper {
      justify-content: flex-start;
    }
  }

  &.right {
    text-align: right;

    .th-wrapper {
      justify-content: flex-end;
    }
  }

  &.center {
    text-align: center;

    .th-wrapper {
      justify-content: center;
    }
  }
}
