@import '../../../_Constants.scss';

.beta-tag {
  border-radius: 44px;
  font-weight: bold;
  padding: 0px 10px;
  letter-spacing: -0.02em;
  margin-left: 8px;
  &-light {
    background: $RoyalBlueA20;
    color: $RoyalBlue;
  }
  &-dark {
    background: $RoyalBlueA60;
    color: $White;
  }
  &-big {
    font-size: 14px;
    height: 26px;
    line-height: 26px;
  }
  &-small {
    font-size: 12px;
    height: 22px;
    line-height: 22px;
  }
}
