@import '../../../_Constants.scss';

.v3-rating-pill {
  height: 26px;
  width: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: $White;
  font-weight: 600;
  font-size: 14px;
  box-sizing: border-box;

  &.info,
  &.na {
    background-color: $White;
    border: 1px solid $NileBlue;
    text-transform: capitalize;
    color: $NileBlue;
  }

  &.letter {
    width: 26px;
  }
}

.v3-rating-pill.composite {
  height: 26px;
  width: 62px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  color: $White;
  font-weight: 600;
  font-size: 14px;

  .v3-rating-pill-rating {
    box-sizing: border-box;
    flex: 1;
    height: 26px;
    background-color: $White;
    color: $NileBlue;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $NileBlue;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.v3-rating-pill {
  &.f {
    background-color: $BrightRed;
    .v3-rating-pill-rating {
      border-color: $BrightRed;
    }
  }
  &.d {
    background-color: $BurntOrange;
    color: $DeepNavyBlue;
    .v3-rating-pill-rating {
      border-color: $BurntOrange;
    }
  }
  &.c {
    background-color: $CreamCanYellow;
    color: $DeepNavyBlue;
    .v3-rating-pill-rating {
      border-color: $CreamCanYellow;
    }
  }
  &.b {
    background-color: $RoyalBlue;
    .v3-rating-pill-rating {
      border-color: $RoyalBlue;
    }
  }
  &.a {
    background-color: $JewelGreen;
    .v3-rating-pill-rating {
      border-color: $JewelGreen;
    }
  }
  &.info {
    &.composite {
      border: none;
    }
    .v3-rating-pill-rating {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}

.v3-rating-pill-letter {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
