@import '../../../_Constants.scss';

.v3-world-map-container {
  position: relative;
  border: 1px solid $GeyserGray;
  border-radius: 4px;
  background: #fafafa;
  box-shadow: inset 0px 0px 24px rgba(0, 0, 0, 0.04);

  .world-map-tooltip-container {
    .country {
      color: $NileBlue;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.02em;
    }
    .value {
      color: $FiordBlue;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.02em;
    }
  }
}
