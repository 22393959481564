@import '../../../_Constants.scss';

div.v3-select {
  position: relative;
  margin-bottom: 30px;
  .css-tlfecz-indicatorContainer svg {
    color: $JewelGreen;
  }

  &.error > .focus-indicator {
    width: 100%;
    background: $BrightRed;
  }

  &.error > .error-text {
    font-size: 10px;
    color: $BrightRed;
    position: absolute;
    bottom: -20px;
    right: 0;
  }
  .default-icon {
    margin-left: 5px;
    transition: all 0.1s;
    transform: rotate(-180deg);
    // opacity: .25;
  }
  .rotate {
    transition: all 0.1s;
    transform: rotate(0deg);
  }

  label {
    display: block;
    font-size: 12px;
    padding: 8px 0;
    font-weight: 600;
    &.disabled {
      color: $Silver;
    }
  }
}

// Dropdown selectors to add disabled icon
.disabled {
  cursor: not-allowed;
  div[class*='-container'] {
    div[class*='-control'] {
      div[class*='-ValueContainer'] {
        div[class*='-placeholder'] {
          font-size: 14px;
          color: $FiordBlue;
          opacity: 0.5;
        }
      }
    }
  }
  .disabled-icon {
    margin-left: 5px;
    transition: all 0.1s;
    transform: rotate(-180deg);
    opacity: 0.25;
  }
}
