@import '../../../_Constants';
.v3-percentage-rating-pill {
  height: 26px;
  width: 62px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  color: $White;
  font-weight: 600;
  font-size: 14px;

  &.info {
    background-color: $White;
    border: 1px solid $NileBlue;
    text-transform: capitalize;
    color: $NileBlue;
    .v3-percentage-rating-pill-letter {
      width: 62px;
    }
  }

  &.f {
    background-color: $BrightRed;
    .v3-percentage-rating-pill-rating {
      border-color: $BrightRed;
    }
  }
  &.d {
    background-color: $BurntOrange;
    color: $DeepNavyBlue;
    .v3-percentage-rating-pill-rating {
      border-color: $BurntOrange;
    }
  }
  &.c {
    background-color: $CreamCanYellow;
    color: $DeepNavyBlue;
    .v3-percentage-rating-pill-rating {
      border-color: $CreamCanYellow;
    }
  }
  &.b {
    background-color: $RoyalBlue;
    .v3-percentage-rating-pill-rating {
      border-color: $RoyalBlue;
    }
  }
  &.a {
    background-color: $JewelGreen;
    .v3-percentage-rating-pill-rating {
      border-color: $JewelGreen;
    }
  }
  &.zero {
    background-color: $GeyserGray;
    color: $DeepNavyBlueA25;
    .v3-percentage-rating-pill-rating {
      border-color: $GeyserGray;
      color: $DeepNavyBlueA25;
    }
  }

  .v3-percentage-rating-pill-rating {
    box-sizing: border-box;
    flex: 1;
    height: 26px;
    background-color: $White;
    color: $NileBlue;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $NileBlue;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .v3-percentage-rating-pill-letter {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
  }
}
