@import '../../../_Constants.scss';

.tippy-tooltip.v3-theme {
  color: $PeriwinkleGray;
  font-weight: normal;
  background-color: $DeepNavyBlue;
  box-shadow: $LargeBoxShadow;
  border-radius: 2px;
  padding: 10px 20px;
  text-align: left;
  overflow-wrap: break-word;
}

.tippy-popper[x-placement^='top'] .tippy-tooltip.v3-theme [x-arrow].arrow-big {
  border-top: 10px solid $DeepNavyBlue;
}
.tippy-popper[x-placement^='bottom']
  .tippy-tooltip.v3-theme
  [x-arrow].arrow-big {
  border-bottom: 10px solid $DeepNavyBlue;
}
.tippy-popper[x-placement^='right']
  .tippy-tooltip.v3-theme
  [x-arrow].arrow-big {
  border-right: 10px solid $DeepNavyBlue;
}
.tippy-popper[x-placement^='left'] .tippy-tooltip.v3-theme [x-arrow].arrow-big {
  border-left: 10px solid $DeepNavyBlue;
}

.tippy-tooltip.v3-light-theme {
  color: $DeepNavyBlue;
  font-weight: normal;
  background-color: $White;
  box-shadow: $LargeBoxShadow;
  border-radius: 2px;
  padding: 10px 20px;
  border: 1px solid $GeyserGray;
  overflow-wrap: break-word;
}

.tippy-popper[x-placement^='top']
  .tippy-tooltip.v3-light-theme
  [x-arrow].arrow-big {
  border-top: 10px solid $White;
}
.tippy-popper[x-placement^='bottom']
  .tippy-tooltip.v3-light-theme
  [x-arrow].arrow-big {
  border-bottom: 10px solid $White;
}
.tippy-popper[x-placement^='right']
  .tippy-tooltip.v3-light-theme
  [x-arrow].arrow-big {
  border-right: 10px solid $White;
}
.tippy-popper[x-placement^='left']
  .tippy-tooltip.v3-light-theme
  [x-arrow].arrow-big {
  border-left: 10px solid $White;
}
