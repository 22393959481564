#glossary-of-terms {
  margin: 0 auto;
  width: 1140px;
  padding-top: 30px;

  .v3-header {
    margin-bottom: 20px;
  }

  .v3-container {
    span.term {
      font-weight: bold;
    }
  }
}
