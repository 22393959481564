@import '../../../_Constants.scss';

.filter-select-container {
  position: relative;
  .dropdown-container {
    position: absolute;
    top: 41px;
    background: $White;
    box-shadow: 0px 5px 14px rgba(14, 36, 53, 0.08);
    border: 1px solid $GeyserGray;
    box-sizing: border-box;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 2;
    height: 416px;

    .filter-checkboxes {
      max-height: 303px;
      overflow-y: auto;
      .filter-loading {
        margin-top: 120px;
        .loading-text {
          margin-top: 10px;
        }
      }
      .filter-checkbox-container {
        display: flex;
        padding: 8px 15px;
        color: $FiordBlue;
        align-items: center;
        label {
          overflow-wrap: break-word;
          word-wrap: break-word;
          hyphens: auto;
        }
        input[type='checkbox']:disabled + label {
          border: unset;
        }
        input[type='checkbox']:disabled + label:after {
          content: '\20E0';
          font-size: 24px;
          position: relative;
          color: $NileBlue;
          opacity: 0.25;
          top: -4px;
          left: -1px;
        }
        .checkbox-wrapper {
          width: 40px;
        }

        &:hover {
          background-color: $AthensGray;
          font-weight: 600;
        }
        .checked {
          font-weight: 600;
        }
        .disabled {
          color: $NileBlue;
          opacity: 0.25;
          top: 1px;
          position: relative;
        }
      }
      .child {
        padding-left: 49px;
      }
      .empty-state {
        display: flex;
        width: 100%;
        text-align: center;
        font-size: 14px;
        line-height: 19px;
        justify-content: center;
        margin-top: 16px;
      }
    }
    .filter-search {
      margin-bottom: 0;
      margin-left: 15px;
      input {
        padding-right: 36px;
      }
    }
    .filter-search-icon {
      cursor: pointer;
      margin-right: 15px;
      margin-bottom: 6px;
    }
    .filter-actions {
      position: absolute;
      bottom: 0;
      border-top: 1px solid $GeyserGray;
      display: flex;
      margin: 0 15px 15px;
      background-color: $White;
      button {
        margin-top: 15px;
        padding: 0;
        &:last-child {
          margin-left: 80px;
        }
      }
    }
  }
  .filter-checkboxes > div > label {
    padding-top: 1px;
    width: 100%;
    cursor: pointer;
    @extend .label-text;
  }

  .label-text {
    font-size: 14px;
    line-height: 24px;
  }

  .border-outline {
    border: 1px solid $GeyserGray;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .filter-select-button-wrapper {
    .filter-select-button {
      display: flex;
      align-items: center;
      height: 44px;
      background: $White;
      color: $FiordBlue;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @extend .label-text;
      @extend .border-outline;
      .selected {
        border-color: $RoyalBlue;
      }

      .filter-icon {
        margin-left: 5px;
        transition: all 0.1s;
        transform: rotate(-180deg);
      }

      .rotate {
        transform: rotate(0deg);
      }
    }

    .filter-select-button.disabled {
      color: $NileBlue;
      opacity: 0.25;
    }
  }

  .display-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 5px;
  }
}
.filter-loading-more-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    margin-bottom: 4px;
    font-size: 12px;
  }
}
