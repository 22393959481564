#user-guide {
  margin: 0 auto;
  width: 1140px;
  padding-top: 30px;

  h1.v3-header {
    margin-bottom: 20px;
  }

  h3.v3-header {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .v3-container {
    table.risk-rating-table {
      margin: 20px auto;
      border-collapse: collapse;
      border-radius: 5px;

      th,
      td {
        padding: 5px;
        border: 1px solid #173a56;
      }
    }

    table.numeric-score-table {
      th.letter {
        width: 25px;
      }
      td:nth-child(1) {
        font-weight: 600;
      }
    }
  }
}
