@import '../../../../_Constants.scss';

.open-slideout-cell {
  cursor: pointer;

  .open-slideout-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    fill: $RoyalBlue;
  }
}
