@import '../../../_Constants.scss';

.v3-bar-chart {
  position: relative;

  .no-vendors-to-display-container {
    position: absolute;
    top: calc(50% - 62px);
    left: calc(50% - 115px);
    padding: 20px;
    text-align: center;
    background: $White;
  }

  svg {
    & rect.f {
      fill: $BrightRed;
    }
    & rect.d {
      fill: $BurntOrange;
    }
    & rect.c {
      fill: $CreamCanYellow;
    }
    & rect.b {
      fill: $RoyalBlue;
    }
    & rect.a {
      fill: $JewelGreen;
    }

    g.y-ticks,
    g.x-ticks,
    g.grid-rows,
    g.x-axis,
    g.y-axis {
      line {
        stroke: $GeyserGray;
        stroke-width: 1;
      }
    }
    g.x-ticks,
    .y-ticks {
      text {
        text-transform: capitalize;
        font-weight: 600;
        fill: $NileBlue;
        font-size: 12px;
        // stroke-width: 1;
      }
    }
    g.x-sub-ticks {
      text {
        font-weight: normal;
        fill: $NileBlue;
        font-size: 12px;
      }
    }
    text.bar-values {
      font-weight: normal;
      fill: $FiordBlue;
    }
    rect.bar-values-bg {
      fill: $White;
      rx: 5px;
    }
  }
}
