@import '../../../_Constants.scss';

label.v3-radio-input {
  position: relative;
  cursor: pointer;

  input {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  .visual {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    border: 1px solid $GeyserGray;
    border-radius: 100%;
    margin-right: 16px;
  }

  input:checked + .visual::before {
    opacity: 1;
    transform: scale(0.6);
  }

  .visual::before,
  .visual::after {
    content: '';
    display: block;

    width: inherit;
    height: inherit;

    border-radius: inherit;

    position: absolute;
    transform: scale(0);
    transform-origin: center center;
  }

  .visual:before {
    background: $JungleGreen;
    opacity: 0;
    transition: 0.3s;
  }

  .visual::after {
    background: $JungleGreen;
    opacity: 0.4;
    transition: 0.6s;
  }
  &:hover {
    input + .visual::before {
      opacity: 0.2;
      transform: scale(0.6);
    }
    input:checked + .visual::before {
      opacity: 1;
      transform: scale(0.6);
    }
  }
}
.hover {
  input + .visual::before {
    opacity: 0.2;
    transform: scale(0.6);
  }
  input:checked + .visual::before {
    opacity: 1;
    transform: scale(0.6);
  }
}
