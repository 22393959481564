@import '../../../_Constants.scss';

button.v3-card {
  background: $White;
  border: 1px solid $GeyserGray;
  border-radius: 10px;
  transition: all 0.3s ease;
  margin-bottom: 30px;

  &:hover {
    box-shadow: $LargeBoxShadow;
    cursor: pointer;
  }
}
