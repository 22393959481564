@import '_MCR_Constants.scss';
@import '_Constants.scss';

.tooltip-mcr {
  margin-left: 3px;

  svg {
    fill: $Silver;
    &:hover {
      fill: $RoyalBlue;
    }
  }
}
