.error-boundary-wrapper {
  margin: 0 auto;
  max-width: 700px;
  min-height: calc(100vh - 175px);
  padding-top: 50px;

  .reload-link {
    color: #70a9d6;
    margin: 20px 0;
    font-size: 16px;
    cursor: pointer;
  }

  .error-details {
    white-space: pre-wrap;
    margin-top: 20px;
  }
}
