@import '../../../_Constants.scss';

div.v3-textarea {
  position: relative;
  margin-bottom: 30px;

  label {
    display: block;
    padding: 8px 0;
    font-size: 12px;
  }

  textarea {
    border: 1px solid $GeyserGray;
    outline: none;
    width: 100%;
    height: 100%;
    resize: none;
    border-radius: 4px;
    font-size: $FontSize;
    padding: 8px 10px;
    box-sizing: border-box;

    &:focus {
      border-color: $JewelGreen;
    }
  }
}
