/******   Colors   ******/
$FireFly: #0c1e2b;
$FireFlyA25: rgba(12, 30, 43, 0.25);
$DeepNavyBlue: #0e2435;
$DeepNavyBlueA05: rgba(14, 36, 53, 0.05);
$DeepNavyBlueA25: rgba(14, 36, 53, 0.25);
$DeepNavyBlueA60: rgba(14, 36, 53, 0.6);
$NileBlue: #173a56;
$NileBlueA05: rgba(23, 58, 86, 0.05);
$NileBlueA25: rgba(23, 58, 86, 0.25);
$NileBlueA50: rgba(23, 58, 86, 0.5);
$NileBlueA70: rgba(23, 58, 86, 0.7);
$ToryBlue: #1056b2;
$RoyalBlue: #2f80ed;
$RoyalBlueA05Solid: #f5f9fe;
$RoyalBlueA05: rgba(47, 128, 237, 0.05);
$RoyalBlueA20: rgba(47, 128, 237, 0.2);
$RoyalBlueA25: rgba(47, 128, 237, 0.25);
$RoyalBlueA50: rgba(47, 128, 237, 0.5);
$RoyalBlueA60: rgba(47, 128, 237, 0.6);
$RoyalBlueA75: rgba(47, 128, 237, 0.75);
$FiordBlue: #435a70;
$FiordBlueA50: rgba(67, 90, 112, 0.5);
$PeriwinkleGray: #b5cde3;
$PeriwinkleGrayA25: rgba(181, 205, 227, 0.25);
$Silver: #bdbdbd;
$GeyserGray: #d5dde4;
$GeyserGrayA40: rgba(213, 221, 228, 0.4);
$GeyserGrayA50: rgba(213, 221, 228, 0.5);
$JungleGreen: #27ae60;
$JungleGreenA25: rgba(39, 174, 96, 0.25);
$JungleGreenA50: rgba(39, 174, 96, 0.5);
$JungleGreenA75: rgba(39, 174, 96, 0.75);
$JewelGreen: #168342;
$JewelGreenA05Solid: #f3f9f6;
$JewelGreenA05: rgba(22, 131, 66, 0.05);
$JewelGreenA25: rgba(22, 131, 66, 0.25);
$JewelGreenA50: rgba(22, 131, 66, 0.5);
$JewelGreenA75: rgba(22, 131, 66, 0.75);
$BottleGreen: #126d36;
$AthensGray: #f9fafb;
$White: #ffffff;
$BrightRed: #b30015;
$BrightRedA05Solid: #fbf2f3;
$BrightRedA05: rgba(179, 0, 21, 0.05);
$BrightRedA25: rgba(179, 0, 21, 0.25);
$BrightRedA50: rgba(179, 0, 21, 0.5);
$BrightRedA75: rgba(179, 0, 21, 0.75);
$Scarlett: #9b0012;
$OrangePeel: #ff9900;
$OrangePeelA25: rgba(255, 153, 0, 0.25);
$OrangePeelA50: rgba(255, 153, 0, 0.5);
$OrangePeelA75: rgba(255, 153, 0, 0.75);
$YellowOrange: #fbb040;
$YellowOrangeA25: rgba(251, 176, 64, 0.25);
$YellowOrangeA50: rgba(251, 176, 64, 0.5);
$YellowOrangeA75: rgba(251, 176, 64, 0.75);
$CreamCanYellow: #f2c94c;
$CreamCanYellowA05Solid: #fefcf6;
$CreamCanYellowA05: rgba(242, 201, 76, 0.05);
$CreamCanYellowA25: rgba(242, 201, 76, 0.25);
$CreamCanYellowA50: rgba(242, 201, 76, 0.5);
$CreamCanYellowA75: rgba(242, 201, 76, 0.75);
$BurntOrange: #fb9b0b;
$BurntOrangeA05: rgba(251, 155, 11, 0.05);
$BurntOrangeA25: rgba(251, 155, 11, 0.25);
$BurntOrangeA50: rgba(251, 155, 11, 0.5);
$BurntOrangeA75: rgba(251, 155, 11, 0.75);
$BurntSienna: #eb5757;
$DianneBlue: #203545;

$WhiteOverlay: rgba(255, 255, 255, 0.85);

/******   Shadows   ******/
$SmallBoxShadow: 0px 4px 14px rgba(14, 36, 53, 0.04);
$LargeBoxShadow: 0px 8px 18px rgba(14, 36, 53, 0.08);

$SidebarHeadShadow: 0px 8px 24px rgba(10, 27, 38, 0.08);

$ButtonContainerBoxShadow: 0px 4px 14px rgba(14, 36, 53, 0.18);

/******   Fonts   ******/
$rrFont: 'Open Sans', Calibri, Arial, sans-serif;
$FontSize: 14px;
$FontColor: $NileBlue;
$LineHeight: 20px;

/******   Mixins   ******/

@mixin v3-text-colors {
  &.v3-red {
    color: $BrightRed;
  }

  &.v3-green {
    color: $JewelGreen;
  }

  &.v3-blue {
    color: $RoyalBlue;
  }

  &.v3-yellow {
    color: $CreamCanYellow;
  }

  &.v3-default {
    color: $NileBlue;
  }
}

@mixin bold-table-data-fix {
  &::after {
    display: block;
    content: attr(data-value);
    font-weight: 600;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  } // this is a little secret sauce to prevent the tds from resizing on hover due to font-weight change. https://stackoverflow.com/questions/5687035/css-bolding-some-text-without-changing-its-containers-size
}
