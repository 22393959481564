@import '../../../_Constants.scss';

button.reset.v3-sub-nav-item {
  font-size: 12px;
  outline: none;
  position: relative;
  color: $NileBlueA50;
  padding: 0 5px 17px;
  margin-right: 24px;
  cursor: pointer;

  &:hover {
    color: $NileBlueA70;
  }

  &:before {
    // prevent element widths from changing when toggling between normal and bold font-weight
    display: block;
    content: attr(data-value);
    font-weight: bold;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }

  &.active {
    color: $NileBlue;
    font-weight: bold;

    &:after {
      // give the border ends a rounded look
      display: flex;
      content: '';
      width: 100%;
      border-bottom: 3px solid $JungleGreen;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 1.5px;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}
