@import '../../../_Constants.scss';

.checkbox-select-container {
  position: relative;
  .checkboxes {
    position: absolute;
    width: 300px;
    max-height: 260px;
    top: 41px;
    overflow-y: auto;
    background: $White;
    box-shadow: 0px 5px 14px rgba(14, 36, 53, 0.08);
    border: 1px solid $GeyserGray;
    box-sizing: border-box;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 2;
    .checkbox-container {
      display: flex;
      padding: 10px 10px;
      color: $FiordBlue;
      align-items: center;

      input[type='checkbox']:disabled + label {
        border: unset;
      }
      input[type='checkbox']:disabled + label:after {
        content: '\20E0';
        font-size: 24px;
        position: relative;
        color: $NileBlue;
        opacity: 0.25;
        top: -4px;
        left: -1px;
      }
      .checkbox-wrapper {
        width: 40px;
      }

      &:hover {
        background-color: $AthensGray;
        font-weight: 600;
      }
      .checked {
        font-weight: 600;
      }
      .disabled {
        color: $NileBlue;
        opacity: 0.25;
        top: 1px;
        position: relative;
      }
    }
  }

  .checkboxes > div > label {
    padding-top: 1px;
    width: 100%;
    cursor: pointer;
    @extend .label-text;
  }

  .label-text {
    font-size: 14px;
    line-height: 24px;
  }

  .border-outline {
    border: 1px solid $GeyserGray;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .checkbox-select-button-wrapper {
    .checkbox-select-button {
      display: flex;
      align-items: center;
      width: 300px;
      height: 44px;
      background: $White;
      color: $FiordBlue;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @extend .label-text;
      @extend .border-outline;
      .selected {
        border-color: $RoyalBlue;
      }

      .filter-icon {
        margin-left: 5px;
        transition: all 0.1s;
        transform: rotate(-180deg);
      }

      .rotate {
        transform: rotate(0deg);
      }
    }

    .checkbox-select-button.disabled {
      color: $NileBlue;
      opacity: 0.25;
    }
  }

  .display-text {
    width: 265px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 5px;
  }
}
