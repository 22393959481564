@import '../../../_Constants.scss';

.checkbox-wrapper {
  position: relative;

  label {
    background-color: $White;
    border: 1px solid $GeyserGray;
    // border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
  }

  label:after {
    border: 2px solid $White;
    border-top: none;
    border-right: none;
    content: '';
    height: 5px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-50deg);
    width: 10px;
  }

  input[type='checkbox'] {
    visibility: hidden;
  }

  input[type='checkbox']:disabled + label {
    cursor: not-allowed;
  }

  input[type='checkbox']:hover:not(:checked):not(:disabled) + label {
    background-color: $JungleGreenA25;
    &::after {
      border: 2px solid $White;
      border-top: none;
      border-right: none;
      content: '';
      height: 5px;
      left: 4px;
      opacity: 1;
      position: absolute;
      top: 5px;
      transform: rotate(-50deg);
      width: 10px;
    }
  }

  input[type='checkbox']:checked + label {
    background-color: $JungleGreen;
  }

  input[type='checkbox']:checked + label:after {
    opacity: 1;
  }
}
