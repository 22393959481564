@import-normalize;

body {
  height: auto;
  min-height: 100%;
}

html {
  // react-joyride fix, will not scroll if line-height is set globally (even semi-globally).
  // search "react-joyride fix" for other fixes
  line-height: unset;
}

@import './_Variables';
@import './_Constants.scss';

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: local('Open Sans'),
    url('./assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 200;
  src: url('./assets/fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url('./assets/fonts/OpenSans/OpenSans-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  src: url('./assets/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'MarkForMC';
  font-weight: 300;
  src: url('./assets/fonts/MarkOffc/MarkForMC-Lt.ttf') format('truetype');
}

@font-face {
  font-family: 'MarkForMC';
  font-weight: bold;
  src: url('./assets/fonts/MarkOffc/MarkForMC-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'MarkForMC';
  font-weight: 500;
  src: url('./assets/fonts/MarkOffc/MarkForMC-Med.ttf') format('truetype');
}

@font-face {
  font-family: 'MarkForMC';
  font-weight: 400;
  src: url('./assets/fonts/MarkOffc/MarkForMC.ttf') format('truetype');
}

html {
  background: $AthensGray;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font;
  font-size: $FontSize;
  font-weight: 200;
  color: $FontColor;
  box-sizing: border-box;
}

a {
  color: #70a9d6;
  text-decoration: none;

  &:hover {
    color: #70a9d6;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font;
}

h1 {
  // font-size: 29px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font;
  font-weight: 300;
}

/* clearfix */
.clearfix:before,
.clearfix:after {
  content: '\0020';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}
.full-height {
  height: 100%;
}
body {
  min-width: 1140px !important;
  overflow-x: auto !important;
}

@media only screen and (min-width: 1140px) {
  #portal-container {
    padding-top: 56px;

    #portal-nav {
      position: fixed;
      top: 0px;
    }
  }
}

#portal-container {
  background: $AthensGray;

  #content-container {
    margin: 0 auto;
    width: 1140px;
    min-height: calc(100vh - 175px);
  }

  #content-container.added-margin {
    margin-top: 83px;
  }

  #content-container.v3-added-margin {
    margin-top: 305px;
  }

  #content-container.v3-added-margin-report {
    margin-top: 235px;
  }

  #content-container.v3-added-margin-sys-admin {
    margin-top: 165px;
  }

  #content-container.single-toe-margin-fix {
    margin-top: 205px;
  }

  #content-container.sub-sub-nav-present {
    margin-top: 270px;
  }
}

span.footer {
  line-height: 60px;
  margin-bottom: 30px;
  margin-top: 60px;
  clear: both;
  color: #95a5a6;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  display: block;
  font-size: 100%;
  font: inherit;
}
header {
  background: #4f4f51;
  line-height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  text-align: right;
  width: 100%;
  height: 55px;
  z-index: 12;
}
header .wrap {
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 20px;
  height: 55px;
}

/****************************
*
*		 Moved from dashboard
*
****************************/

#dashboard {
  .paper {
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    margin: 0 -15px 20px;
    padding: 10px 20px 30px;
    overflow: auto;
    margin-top: 35px;
  }

  h2.portfolio-title {
    font-size: 20px;
    font-weight: 200;
    line-height: 20px;
    margin-top: 0;
  }

  span.rating {
    display: inline-block;
    min-width: 43px;
    color: white;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: center;
    border-radius: 3px;
    text-shadow: 1px 1px 1px #999;

    &.modal {
      text-shadow: none;
      font-weight: 400;
    }

    &.drop {
      padding-right: 5px;
    }
  }
  .trend.red {
    color: red !important;
  }
  .trend.green {
    color: #6bbf47 !important;
  }
  .trend {
    text-align: right;
  }
}

ul.reset,
li.reset {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

button.reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  // &:focus {
  //     outline: 2px solid #d71ef7;
  // }
}

// *,
// :after,
// :before {
//   box-sizing: inherit;
// }

*,
*:before,
*:after {
  box-sizing: border-box;
}
