@import '../../../_Constants.scss';

.return-link-container {
  display: flex;
  margin-top: 30px; // this weirdness is to make the sticky header added margin happy.

  .return-link {
    display: flex;
    align-items: center;
    margin-top: -10px; // this weirdness is to make the sticky header added margin happy.
    text-decoration: none;

    .link-text {
      font-size: 12px;
      letter-spacing: -0.01em;
      color: $FiordBlue;
      margin-left: 8px;
    }

    svg {
      fill: $BurntSienna;
    }
  }
}
